import React from "react";
import PropTypes from "prop-types";

import JsComponents from "./sections/js-components.jsx";
import img1 from "../../assets/images/ui/iFood-1.png";
import img2 from "../../assets/images/ui/iFood-2.png";
import img3 from "../../assets/images/ui/iFood-3.png";
import img4 from "../../assets/images/ui/iFood-4.png";
import img5 from "../../assets/images/ui/iFood-5.png";
import img6 from "../../assets/images/ui/iFood-6.png";

const IFood = () => {
  const iFoodItems = [
    {
      src: img1,
      altText: "",
      caption: "",
    },
    {
      src: img2,
      altText: "",
      caption: "",
    },
    {
      src: img3,
      altText: "",
      caption: "",
    },
    {
      src: img4,
      altText: "",
      caption: "",
    },
    {
      src: img5,
      altText: "",
      caption: "",
    },
    {
      src: img6,
      altText: "",
      caption: "",
    },
  ];

  return (
    <div id="main-wrapper">
      <div className="page-wrapper">
        <div className="container-fluid">
          <JsComponents
            id="iFood"
            title="iFood"
            subtitle="卡路里、AI食谱、临期提醒、有关食物的一切~"
            items={iFoodItems}
            isSupport={true}
          />
        </div>
      </div>
    </div>
  );
};

IFood.propTypes = {
  classes: PropTypes.object,
};

export default IFood;
