import React from "react";
import PropTypes from "prop-types";

// core components
import Header from "../../components/header/header.jsx";
import HeaderBanner from "../../components/banner/banner.jsx";
import Footer from "../../components/footer/footer.jsx";

import JsComponents from "./sections/js-components.jsx";
import CallToAction from "../../components/call-to-action/CallToAction";
import img1 from "../../assets/images/ui/magicmoments-1.jpeg";
import img2 from "../../assets/images/ui/magicmoments-2.jpeg";
import img3 from "../../assets/images/ui/magicmoments-3.jpeg";
import img4 from "../../assets/images/ui/magicmoments-4.jpeg";
import img5 from "../../assets/images/ui/magicmoments-5.jpeg";
import img6 from "../../assets/images/ui/magicmoments-6.jpeg";

import img7 from "../../assets/images/ui/memorybag-1.jpeg";
import img8 from "../../assets/images/ui/memorybag-2.jpeg";
import img9 from "../../assets/images/ui/memorybag-3.jpeg";

import img10 from "../../assets/images/ui/abbr1.png";
import img11 from "../../assets/images/ui/abbr2.png";
import img12 from "../../assets/images/ui/abbr3.png";

import img13 from "../../assets/images/ui/iFood-1.png";
import img14 from "../../assets/images/ui/iFood-2.png";
import img15 from "../../assets/images/ui/iFood-3.png";
import img16 from "../../assets/images/ui/iFood-4.png";
import img17 from "../../assets/images/ui/iFood-5.png";
import img18 from "../../assets/images/ui/iFood-6.png";

const Components = () => {
  const magicmomentsItems = [
    {
      src: img1,
      altText: "",
      caption: "",
    },
    {
      src: img2,
      altText: "",
      caption: "",
    },
    {
      src: img3,
      altText: "",
      caption: "",
    },
    {
      src: img4,
      altText: "",
      caption: "",
    },
    {
      src: img5,
      altText: "",
      caption: "",
    },
    {
      src: img6,
      altText: "",
      caption: "",
    },
  ];
  const memorybagItems = [
    {
      src: img7,
      altText: "",
      caption: "",
    },
    {
      src: img8,
      altText: "",
      caption: "",
    },
    {
      src: img9,
      altText: "",
      caption: "",
    },
  ];
  const abbrItems = [
    {
      src: img10,
      altText: "",
      caption: "",
    },
    {
      src: img11,
      altText: "",
      caption: "",
    },
    {
      src: img12,
      altText: "",
      caption: "",
    },
  ];
  const iFoodItems = [
    {
      src: img13,
      altText: "",
      caption: "",
    },
    {
      src: img14,
      altText: "",
      caption: "",
    },
    {
      src: img15,
      altText: "",
      caption: "",
    },
    {
      src: img16,
      altText: "",
      caption: "",
    },
    {
      src: img17,
      altText: "",
      caption: "",
    },
    {
      src: img18,
      altText: "",
      caption: "",
    },
  ];
  return (
    <div id="main-wrapper">
      <Header />
      <div className="page-wrapper">
        <div className="container-fluid">
          <HeaderBanner />
          {/* <Buttons />
                    <Labels />
                    <PagePagination /> */}
          <JsComponents
            id="iFood"
            title="iFood"
            subtitle="卡路里、AI食谱、临期提醒、有关食物的一切~"
            items={iFoodItems}
            url="https://apps.apple.com/us/app/ifood-cal-storage-ai-recipe/id6740173392"
          />
          <JsComponents
            id="Abbreviation AI"
            title="缩写查询AI"
            subtitle="你是否被缩写困扰过吗？现在，你可以用AI帮你查询缩写背后的含义，也可以进行传统的查询"
            items={abbrItems}
            url="https://apps.apple.com/us/app/abbreviation-ai/id6736823474"
          />
          <JsComponents
            id="magicmoments"
            title="创意圈圈"
            subtitle="选择你最喜欢的圈圈模板，裁切你的图片，你就是朋友圈和PPT圈最靓的崽"
            items={magicmomentsItems}
            url="https://apps.apple.com/us/app/magic-moments-croping-images/id6608961008"
          />
          <JsComponents
            id="memorybag"
            title="记忆背包"
            subtitle="简单、快速的保存你的每一次记忆"
            items={memorybagItems}
            url="https://apps.apple.com/us/app/memory-bag-org-picture-video/id6608967106"
          />
          <CallToAction />
        </div>
      </div>
      <Footer />
    </div>
  );
};

Components.propTypes = {
  classes: PropTypes.object,
};

export default Components;
